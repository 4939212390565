const Helper = {
  toggleClass(el, className) {
    el.classList.toggle(className);
  },
  async onErrorImage(el, errorImgSrc) {
    el.retries = el.retries || 0;
    el.retries++;
    if (el.retries > 3) {
      el.src = errorImgSrc || '/images/svg/picture_2.svg';
      el.classList.add('error-image');
    } else {
      await sleep(1_000);
      el.src = el.src.replace(/\?retry=\d+/, '');
      el.src = `${el.src}?retry=${el.retries}`;
      console.log(`retry: ${el.retries}`);
    }

    return true;
  },
  collapse(targetEl, collapsedElId, className) {
    const collapsedEl = document.getElementById(collapsedElId);

    if (targetEl.classList.contains(className)) {
      collapsedEl.style.height = 0;
    } else {
      collapsedEl.style.height = `${collapsedEl.scrollHeight}px`;
    }

    this.toggleClass(targetEl, className);
  },
};

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

const init = (function () {
  class Init {
    constructor() {
      document.addEventListener('DOMContentLoaded', this.ready.bind(this));
      this.onModalClose = new Event('onModalClose');
    }

    openModal(content) {
      const keyDownWhenModalOpen = (e) => {
        if (e.key === 'Escape') {
          this.closeModal();
        }
      };

      const afterCloseModal = () => {
        document.removeEventListener('keydown', keyDownWhenModalOpen);
        document.removeEventListener('onModalClose', afterCloseModal);
      };

      const modal = document.createElement('div');
      const modalBackdrop = document.createElement('div');
      const modalContent = document.createElement('div');
      const modalCloseBtn = document.createElement('div');

      modal.classList.add('modal');
      modal.id = 'modal';

      modalBackdrop.classList.add('modal-backdrop');
      modalContent.classList.add('modal-content');
      modalCloseBtn.classList.add('modal-close-btn');

      modalBackdrop.onclick = this.closeModal.bind(this);
      modalCloseBtn.onclick = this.closeModal.bind(this);

      modalContent.appendChild(content);

      modal.appendChild(modalBackdrop);
      modal.appendChild(modalContent);
      modal.appendChild(modalCloseBtn);

      document.addEventListener('keydown', keyDownWhenModalOpen);
      document.addEventListener('onModalClose', afterCloseModal);

      document.body.appendChild(modal);

      document.body.classList.add('modal-opened');
    }

    closeModal() {
      document.body.classList.remove('modal-opened');

      const modal = document.getElementById('modal');

      setTimeout(() => {
        modal.remove();
      }, 300);

      document.dispatchEvent(this.onModalClose);
    }

    removeAllChildren(el) {
      const childEls = el.children;

      while (childEls.length > 0) {
        childEls.item(0).remove();
      }
    }

    async clickImgsView(img) {
      const targetImgSrc = img.target.src;
      const { imgList, current, getSplideContainer } = (img.target.data || {});

      const currentViewIndex = current;
      if (imgList && imgList.length > 1) {
        const splideContainer = await getSplideContainer();
        const { splide } = splideContainer;
        const tmp_speed = splide.options.speed;
        splide.options.speed = 0.000000001; // disable animation
        splide.go(currentViewIndex); // go to current view
        splide.options.speed = tmp_speed; // restore animation speed
        this.openModal(splideContainer);
        splide.refresh();
        // setTimeout(() => {
        //   splide.go(currentViewIndex); // go to current view
        //   splide.refresh();
        // }, 1_000);
        // splide.refresh();
      } else {
        const container = document.createElement('div');
        const containerImage = document.createElement('div');
        const image = new Image();

        containerImage.classList.add('modal-container-image');
        container.classList.add('modal-container-image-wrapper');

        image.src = targetImgSrc;
        image.alt = targetImgSrc;
        image.classList.add('modal-image-full-view');

        image.onerror = function () {
          Helper.onErrorImage(this);
        };

        containerImage.appendChild(image);
        container.appendChild(containerImage);

        this.openModal(container);
      }
    }

    getHeight() {
      if (typeof window.innerWidth === 'number') {
        // Non-IE
        return window.innerHeight;
      }
      if (document.documentElement && (document.documentElement.clientWidth || document.documentElement.clientHeight)) {
        // IE 6+ in 'standards compliant mode'
        return document.documentElement.clientHeight;
      }
      if (document.body && (document.body.clientWidth || document.body.clientHeight)) {
        return document.body.clientHeight;
      }

      return 0;
    }

    getWidth() {
      if (typeof window.innerWidth === 'number') {
        // Non-IE
        return window.innerWidth;
      }
      if (document.documentElement && (document.documentElement.clientWidth || document.documentElement.clientHeight)) {
        // IE 6+ in 'standards compliant mode'
        return document.documentElement.innerWidth;
      }
      if (document.body && (document.body.clientWidth || document.body.clientHeight)) {
        return document.body.innerWidth;
      }

      return 0;
    }

    ready() {
      const menu = document.getElementById('menu');
      const arrowToTop = document.getElementById('arrow-to-top');
      const imgsView = document.getElementsByClassName('img-view');

      let previousResizeValue = this.getWidth();

      menu.getElementsByClassName('nav-items__mobile')[0].style.display = '';

      const scrollFn = (e) => {
        if (e.target.scrollingElement.scrollTop > 60) {
          menu.classList.add('fixed-menu');
          document.body.classList.add('menu-is-fixed');
        } else {
          menu.classList.remove('fixed-menu');
          document.body.classList.remove('menu-is-fixed');
        }

        if (e.target.scrollingElement.scrollTop > this.getHeight() * 2) {
          arrowToTop.classList.add('active');
        } else {
          arrowToTop.classList.remove('active');
        }
      };

      const setFontSizeToPricelist = () => {
        const blocksPricelist = document.getElementsByClassName('block-pricelist-1');

        const checkFontSize = (priceContainer, parentPriceContainer) => {
          const getParentAvailableHeight = () => {
            const {
              paddingBottom,
              paddingTop,
            } = getComputedStyle(parentPriceContainer);
            return parentPriceContainer.offsetHeight - parseFloat(paddingBottom) - parseFloat(paddingTop);
          };

          const parentAvailableHeight = getParentAvailableHeight();

          if (priceContainer.offsetHeight > parentAvailableHeight) {
            // target el to change font size;
            const targetTextBlock = priceContainer.getElementsByClassName('price-item-container__item-text-block')[0];
            const {
              fontSize,
              lineHeight,
            } = getComputedStyle(targetTextBlock);

            targetTextBlock.style.fontSize = `${parseFloat(fontSize) - 1}px`;
            targetTextBlock.style.lineHeight = `${parseFloat(lineHeight) - 1}px`;

            // if (parentAvailableHeight !== getParentAvailableHeight()) {
            checkFontSize(priceContainer, parentPriceContainer);
            // }
          }
        };

        for (let index = 0; index < blocksPricelist.length; index++) {
          // text containers
          const priceContainers = blocksPricelist.item(index).getElementsByClassName('price-item-container__item-text');

          for (let indexJ = 0; indexJ < priceContainers.length; indexJ++) {
            const priceContainer = priceContainers.item(indexJ);
            const parentPriceContainer = priceContainer.parentNode;
            const targetTextBlock = priceContainer.getElementsByClassName('price-item-container__item-text-block')[0];

            targetTextBlock.style.fontSize = '';
            targetTextBlock.style.lineHeight = '';

            checkFontSize(priceContainer, parentPriceContainer);
          }
        }
      };

      const toTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      };

      const checkWidthMenuItems = (menuEl) => {
        const leftPart = document.getElementById('leftPart');
        const navItems = document.getElementById('navItems');

        const logoElWidth = leftPart.getBoundingClientRect().width + 75;
        const containerElWidth = menuEl.getBoundingClientRect().width;

        let maxWidth = containerElWidth - 180 - logoElWidth;
        let index = 0;
        let counter = 0;

        const menuItemsElements = navItems.children;

        for (index; index < menuItemsElements.length; index++) {
          const widthElement = menuItemsElements[index].getBoundingClientRect().width + 40;
          maxWidth -= widthElement;

          if (maxWidth > 0) {
            counter = menuItemsElements.length - index - 1;
          }
        }

        const classes = navItems.classList
          .toString()
          .split(' ')
          .filter((elClass) => !elClass.includes('hide-last'))
          .join(' ');

        navItems.className = `${classes} hide-last-${counter}`;
      };

      const resizeFn = () => {
        const isHorizontal = this.getWidth() / this.getHeight() > 1;
        if (isHorizontal) {
          document.body.classList.add('orientation-horizontal');
        } else {
          document.body.classList.remove('orientation-horizontal');
        }

        if (previousResizeValue > 767 || this.getWidth() > 767) {
          checkWidthMenuItems(menu);
        }

        if (previousResizeValue < 1440 || this.getWidth() < 1440) {
          setFontSizeToPricelist();
        }

        previousResizeValue = this.getWidth();
      };

      arrowToTop.addEventListener('click', toTop);

      for (let i = 0; i < imgsView.length; i++) {
        imgsView.item(i).addEventListener('click', this.clickImgsView.bind(this));
      }

      resizeFn();

      document.addEventListener('scroll', scrollFn);

      window.addEventListener('resize', resizeFn);
    }
  }

  new Init();
}());
